<template>
  <div class="onboarding-form">
    <div class="pb-5 pt-lg-0 pt-3">
      <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
        Pilot Setup <small>(Step 1 of 10)</small>
      </h3>
      <h4 class="mt-5">
        There are few steps required to help you hit the ground running with
        Pilot. You can invite someone from your organisation to complete this
        setup yourself.
        <a @click="watchExplainer()" class="cursor-pointer">
          Watch our explainer video</a
        >
        to learn more<br /><br />
        what would you like to do?
      </h4>
    </div>
    <div class="row m-0">
      <div
        @click="toggleStep(1)"
        class="col bg-light-success px-6 py-5 rounded-xl mr-7 cursor-pointer"
      >
        <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
          <inline-svg src="media/svg/icons/home/building.svg" />
          <i
            v-if="step == 1"
            class="fas fa-check-circle fa-2x text-success float-right"
          ></i>
        </span>
        <a href="#" class="text-success font-weight-bold font-size-h6 mt-2">
          Setup My Organisation
        </a>
        <p class="text-success">
          I'll go through the setup process myself
        </p>
      </div>
      <div
        @click="toggleStep(2)"
        class="col bg-light-info px-6 py-5 rounded-xl cursor-pointer mr-7"
      >
        <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">
          <inline-svg src="media/svg/icons/communication/add-user.svg" />
          <i
            v-if="step == 2"
            class="fas fa-check-circle fa-2x text-info float-right"
          ></i>
        </span>
        <a href="#" class="text-info font-weight-bold font-size-h6 mt-2">
          Invite an Administrator
        </a>
        <p class="text-info">
          Invite an administrator to setup for me
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="pb-lg-0 pb-5 float-right mt-5">
          <button
            type="button"
            class="btn btn-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-5"
            @click="selectSetup()"
          >
            <b-spinner
              v-if="loading == true"
              variant="light"
              label="Spinning"
            ></b-spinner>
            <span v-if="loading == false"
              >Next<i class="fas fa-arrow-right ml-2"></i
            ></span>
          </button>
        </div>
      </div>
    </div>
    <b-modal id="videoModal" size="xl" hide-footer>
      <template #modal-title>Pilot Demo Video</template>
      <div class="d-block text-center">
        <iframe
          style="width:100%; height:500px"
          src="https://www.youtube.com/embed/MOA3fYwcJsM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CompanyService from "@/core/services/company.service.js";
import { mapGetters, mapState } from "vuex";
import { UPDATE_AUTH } from "@/core/services/store/auth.module";
export default {
  data() {
    return {
      step: 1,
      loading: false
    };
  },
  components: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "currentToken"])
  },
  mounted() {},
  methods: {
    selectSetup() {
      try {
        this.loading = true;
        if (this.step == 1) {
          let data = {
            previous_onboarding_stage: 1,
            onboarding_stage: 3,
            onboarding_complete: false
          };

          CompanyService.setOnboardingStage(data)
            .then(response => {
              this.$store.dispatch(UPDATE_AUTH, response.data);
              this.$router.push({ name: "onboarding-choose-setup" });
            })
            .catch(error => {
              if (error.data) {
                this.makeToast("danger", error.statusText, error.data.message);
              } else {
                this.makeToast(
                  "danger",
                  "Oops",
                  "An unexpected exception has occured. Please give it a minute and refresh page"
                );
              }
              this.loading = false;
            });
        } else if (this.step == 2) {
          let data = {
            previous_onboarding_stage: 1,
            onboarding_stage: 2,
            onboarding_complete: false
          };

          CompanyService.setOnboardingStage(data)
            .then(response => {
              this.$store.dispatch(UPDATE_AUTH, response.data);
              this.$router.push({ name: "onboarding-invite-admin" });
            })
            .catch(error => {
              if (error.data) {
                this.makeToast("danger", error.statusText, error.data.message);
              } else {
                this.makeToast(
                  "danger",
                  "Oops",
                  "An unexpected exception has occured. Please give it a minute and refresh page"
                );
              }
              this.loading = false;
            });
        }
      } catch (error) {
        this.loading = false;
        this.makeToast(
          "danger",
          "Oops",
          "An unexpected exception has occured. Please give it a minute and refresh page"
        );
      }
    },

    watchExplainer() {
      this.$bvModal.show("videoModal");
    },

    toggleStep(value) {
      this.step = value;
    },

    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  }
};
</script>
